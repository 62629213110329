import React, { useEffect } from 'react';
import RegulationContainer from './RegulationContainer.component';
import { useDispatch } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import { toggleOpeningAnimation } from '../../../../redux/pages/actions';
import GridItem from '../../../layout/GridComponents/GridItem';
import { Theme } from '@mui/material';
import { DataObject } from '../../../../types/redux/data/dataTypes';

interface DashboardContainersProps {
  inputData: DataObject;
}

const useStyles = makeStyles<Theme>(() => ({
  root: {
    transition: 'all .3s',
    '&:hover': {
      transform: 'scale(0.99)',
    },
  },
}));

const DashboardContainers: React.FC<DashboardContainersProps> = ({
  inputData,
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    setTimeout(() => {
      dispatch(toggleOpeningAnimation());
    }, 6000);
  }, []);

  const classes = useStyles();

  const regulationContainers = inputData.data[0].dashboard_containers.filter(
    (container: any) => container.container_type === 'regulation',
  );

  console.log('regulationContainers', regulationContainers);
  return (
    <>
      {inputData.data[0].dashboard_containers.map(
        (container: any, index: number) => (
          <GridItem
            card
            key={index}
            classes={classes}
            cardStyle={{ height: '30rem' }}
            xs={12}
          >
            {container.container_type === 'regulation' ? (
              <RegulationContainer
                sectionId={container.section_id}
                sectionTitle={container.section_title}
                data={container.data}
                delayIndex={index}
                currencyCode={inputData.data[0].manco_base_currency}
              />
            ) : null}
            {/* Here we allow for expandability with different types of dashboard containers... */}
          </GridItem>
        ),
      )}
    </>
  );
};

export default DashboardContainers;
